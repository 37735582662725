import React from 'react'

function MeetUs() {
  return (
    <div className='meet-us-container'>
      <h2 className='secondary'>Meet us next...</h2>
      <div>
        <span className='secondary'>Emergency Services Show, NEC Birmingham</span>
        <p className='secondary'>18th & 19th September, Hall 4 Stand H90</p>
      </div>
    </div>
  )
}

export default MeetUs